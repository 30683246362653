import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				O nas | Odkryj salon tatuażu Canvas Whisper
			</title>
			<meta name={"description"} content={"Atrament z głębią, historie, które rezonują"} />
			<meta property={"og:title"} content={"O nas | Odkryj salon tatuażu Canvas Whisper"} />
			<meta property={"og:description"} content={"Atrament z głębią, historie, które rezonują"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65d461af0072400020ea53a5/images/4.jpg?v=2024-04-02T13:59:22.674Z"} />
			<link rel={"shortcut icon"} href={"https://quircosale.com/img/4287179.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quircosale.com/img/4287179.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quircosale.com/img/4287179.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						O nas
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Canvas Whisper rozpoczął się od prostego pomysłu: stworzenia przestrzeni, w której sztuka tatuażu wykracza poza zwyczajność, stając się głęboko osobistą narracją dla każdej osoby. Nasza podróż rozpoczęła się jako kolektyw artystów pasjonujących się ożywianiem skomplikowanych projektów i osobistych historii za pomocą atramentu. Z biegiem czasu Canvas Whisper przekształciło się w sanktuarium ekspresji, w którym każdy tatuaż jest arcydziełem współpracy między artystą a klientem. Nasze zaangażowanie w doskonałość, kreatywność i osobisty charakter sprawiły, że staliśmy się kamieniem węgielnym w społeczności sztuki i atramentu.
					</Text>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box width="100%">
					<Image src="https://uploads.quarkly.io/65d461af0072400020ea53a5/images/2.jpg?v=2024-04-02T13:59:22.655Z" width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/65d461af0072400020ea53a5/images/6.jpg?v=2024-04-02T13:59:22.655Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Nasi artyści: Serce Canvas Whisper
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Sercem Canvas Whisper są nasi artyści - każdy z nich wnosi do studia swój unikalny styl, wizję i doświadczenie. Nasi artyści to nie tylko tatuatorzy - to gawędziarze, których celem jest przekształcanie pomysłów w żywą sztukę. Posiadając doświadczenie w różnych formach ekspresji artystycznej, mają wspólny cel: tworzyć znaczące, trwałe dzieła sztuki, które odzwierciedlają wyjątkową podróż każdego klienta, który przechodzi przez nasze drzwi. Ich umiejętności, pasja i zaangażowanie w rzemiosło sprawiają, że Canvas Whisper to coś więcej niż tylko salon tatuażu - to miejsce transformacji i odkrywania siebie.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
			<Text margin="0px 0px 50px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Dlaczego Canvas Whisper się wyróżnia
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="50px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Bogata gama stylów
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Od delikatnych zawiłości tatuaży z cienkimi liniami po odważne stwierdzenia tradycyjnych elementów, nasza różnorodna gama stylów zapewnia, że Twój tatuaż jest naprawdę jedyny w swoim rodzaju.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Poza tatuażem
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Wierzymy w holistyczne podejście do tatuażu, koncentrując się nie tylko na końcowym dziele, ale na samym doświadczeniu tworzenia. Nasze studio to przestrzeń, w której komfort spotyka się z kreatywnością.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Zobowiązanie do jakości
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Tylko najwyższej jakości atramenty i najnowocześniejszy sprzęt są wystarczająco dobre dla naszych klientów. Utrzymujemy niezachwiane zaangażowanie w bezpieczeństwo i higienę.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Nacisk na osobiste relacje
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								W Canvas Whisper nie jesteś tylko klientem - jesteś częścią naszej stale rosnącej rodziny. Poświęcamy czas, aby poznać Ciebie i Twoją historię, zapewniając, że Twój tatuaż jest głęboko osobisty.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Rozpocznij z nami swoją atramentową podróż
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Wybór miejsca, w którym chcesz powierzyć swoje zaufanie i swoją historię, to niemała decyzja. W Canvas Whisper rozumiemy wagę tego wyboru. Dlatego poświęcamy się tworzeniu nie tylko tatuaży, ale także osobistego dziedzictwa. Tutaj Twoja historia jest święta, Twoja wizja szanowana, a Twoja satysfakcja najważniejsza. Dołącz do nas w Canvas Whisper, gdzie każda kropla atramentu opowiada historię, a każdy tatuaż jest krokiem w Twojej wyjątkowej podróży ekspresji.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/65d461af0072400020ea53a5/images/1.jpg?v=2024-04-02T13:59:22.650Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});